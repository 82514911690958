<template>
  <b-card>
    <h4 style="padding-bottom: 15px;">
      Download Mobile Apps</h4>
    <b-row>
      <b-col
        md="6"
        class="d-flex justify-content-center flex-column align-items-center"
      >
        <div
          class="image-container"
          style="margin-bottom:1px;"
        >
          <img
            src="/rider-app.png"
            alt="Example Image"
            style="padding-left:15%;"
          >
        </div>
        <b-button
          v-b-tooltip.hover.bottom.v-primary="'Clicking the download icon will initiate the APK file download for the Rider App'"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="danger"
          class="custom-button"
          :href="apkUrl"
          target="_blank"
          style="margin-top: 10px;"
        >
          <feather-icon
            icon="DownloadIcon"
          />
          Download Rider App
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BButton, VBTooltip,
} from 'bootstrap-vue'
import {
  mapGetters,
} from 'vuex'
import {
  bucketUrl, tenant, apiUrl, appEnv,
} from '@/constants/config'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BButton,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  data() {
    return {
      bucketUrl,
      tenant,
      apiUrl,
      appEnv,
    }
  },

  computed: {
    apkUrl() {
      const appName = appEnv === 'production' ? tenant : `${tenant}dev`
      return `${bucketUrl}apk/app-${appName}-release.apk`
    },
    ...mapGetters(['currentUser']),
  },
}

</script>

<style>
.image-container {
  text-align: center;
}

.custom-button {
  margin-top: 8px;
  margin-left: 60px;

}
</style>
